import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { RepositoryService } from './repository.service';

@Injectable()
export class AuthinterceptorInterceptor implements HttpInterceptor {
  constructor(private authService: RepositoryService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
      const authToken = this.authService.getToken();
      req = req.clone({
          setHeaders: {
              Authorization: "Bearer " + authToken
          }
      });
      return next.handle(req);
  }
}