import { AfterViewInit, Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RepositoryService } from './shared/repository.service';
import { DrpList } from './_models/common-classes';
declare function sidebar(): any;
declare function menucollepse(): any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'demo-aximus'
  public today: number = Date.now();

  constructor(public repoService: RepositoryService) {
    
  }
  ngAfterViewInit() {
    sidebar();
    menucollepse();
    this.getManagerDrp();
  }

  getManagerDrp = () => {
    this.repoService.getDataAsync('masteraccounts/GetManagerdrp').then(d => d.
      subscribe(res => {
        this.repoService.ManagerDrpList = res as DrpList[];
      }));
  }


  onChangeManager(e) {
    //this.ManagerDrpList.find(d => d.id==e.target.value).name
    this.repoService.SetManager(e.target.value);
    location.reload();
  }
}
