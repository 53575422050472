import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { TradersAnalysisChartsComponent } from './pages/traders-analysis-charts/traders-analysis-charts.component';
import { IntroducingBrokerComponent } from './pages/introducing-broker/introducing-broker.component';
import { CreditSheetsComponent } from './pages/credit-sheets/credit-sheets.component';
import { ManagerOverviewComponent } from './pages/manager-overview/manager-overview.component';
import { MarginLevelsComponent } from './pages/margin-levels/margin-levels.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { IntroducingBrokerChartsComponent } from './pages/introducing-broker-charts/introducing-broker-charts.component';
import { ActivateComponent } from './pages/copy-trade/activate/activate.component';
import { ActiveStrategiesComponent } from './pages/copy-trade/active-strategies/active-strategies.component';
import { MetricsComponent } from './pages/copy-trade/metrics/metrics.component';
import { RtActivateComponent } from './pages/reversal-trading/rt-activate/rt-activate.component';
import { RtActivateStrategiesComponent } from './pages/reversal-trading/rt-activate-strategies/rt-activate-strategies.component';
import { RtMetricsComponent } from './pages/reversal-trading/rt-metrics/rt-metrics.component';
import { CActivateComponent } from './pages/coverage/c-activate/c-activate.component';
import { CActivateStrategiesComponent } from './pages/coverage/c-activate-strategies/c-activate-strategies.component';
import { CMetricsComponent } from './pages/coverage/c-metrics/c-metrics.component';
import { NewAccountComponent } from './pages/master-accounts/new-account/new-account.component';
import { ListAccountComponent } from './pages/master-accounts/list-account/list-account.component';
import { NewStrategiesComponent } from './pages/strategies/new-strategies/new-strategies.component';
import { ListStrategiesComponent } from './pages/strategies/list-strategies/list-strategies.component';
import { TradingAccountsComponent } from './pages/trading-accounts/trading-accounts.component';
import { TradersAnalysisComponent } from './pages/trading-accounts/traders-analysis/traders-analysis.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import {MatIconModule} from '@angular/material/icon';
import {  MatDialogModule } from '@angular/material/dialog';
import { MyListComponent } from './pages/my-list/my-list.component';
import { MylistdetailsComponent } from './pages/my-list/mylistdetails/mylistdetails.component';
import {  NgSelect2Module } from 'ng-select2';
import { LockComponent } from './pages/lock/lock.component';
import { AuthinterceptorInterceptor } from './shared/authinterceptor.interceptor';
import { ComparisonComponent } from './pages/comparison/comparison.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    TradersAnalysisChartsComponent,
    IntroducingBrokerComponent,
    CreditSheetsComponent,
    ManagerOverviewComponent,
    MarginLevelsComponent,
    NotFoundComponent,
    TradersAnalysisChartsComponent,
    IntroducingBrokerChartsComponent,
    ActivateComponent,
    ActiveStrategiesComponent,
    MetricsComponent,
    RtActivateComponent,
    RtActivateStrategiesComponent,
    RtMetricsComponent,
    CActivateComponent,
    CActivateStrategiesComponent,
    CMetricsComponent,
    NewAccountComponent,
    ListAccountComponent,
    NewStrategiesComponent,
    ListStrategiesComponent,
    TradingAccountsComponent,
    TradersAnalysisComponent,
    MyListComponent,
    MylistdetailsComponent,
    LockComponent,
    ComparisonComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSortModule,
    MatIconModule,
    MatDialogModule,
    NgSelect2Module

  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthinterceptorInterceptor,
    multi: true
  }],
  exports: [AppRoutingModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
